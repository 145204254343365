import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from "react-bootstrap";
import { MyDiv, HeadingThree, HeadingTwo, PTag, Section } from '../../common/components';
import BeeBoxImage from '../../assets/images/bee-box-anime.gif';

const BeeBoxSection = () => {
    const beeBoxContentRef = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry, index) => {
            if (entry.isIntersecting) {
                const delay = index * 1000; 
                setTimeout(() => {
                    entry.target.classList.add('visible'); 
                }, delay);

                

                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.1 });
      const ulElement = beeBoxContentRef.current;
      if (ulElement) {
        observer.observe(ulElement);
      }
  
      return () => {
        if (ulElement) {
          observer.unobserve(ulElement);
        }
      };
    }, []);

  return (
    <>
      <Section className="beebox-section" id="beebox-section">
        <MyDiv className="container">
          <Row className="align-items-center">
          <Col lg={6} col-12 className="beebox-col">
            <img src={BeeBoxImage} alt="Beebox" className='beebox-image' />
            </Col>
            <Col lg={6} col-12 className="beebox-col mx-auto" >
              <MyDiv className="beebox-content" ref={beeBoxContentRef}>
              <HeadingThree>THE BEEBOX</HeadingThree>
              <HeadingTwo>The name Beebox is derived from
              the industrious nature of bees</HeadingTwo>
              <PTag>
              Who transport critical cargo to their hives for the well-being of their colony. This analogy symbolizes our commitment to efficiently and smoothly moving boxes across the globe, ensuring they reach their destinations promptly and securely, just like bees ensuring the survival of their hive.
              </PTag>
              </MyDiv>
            </Col>
          </Row>
        </MyDiv>
      </Section>
    </>
  );
};

export default BeeBoxSection;
