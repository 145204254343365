import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from "react-bootstrap";
import { MyDiv, HeadingThree, HeadingTwo, PTag, Section } from '../../common/components';
import SpinningImage from '../../assets/images/globe.gif';
import AOS from "aos";
import "aos/dist/aos.css";

const GlobalnetworkSection = () => {
    useEffect(() => {
        AOS.init({
            delay: 100,          
            duration: 1200,     
            offset: 150,  
            easing: 'ease',
            once: true
          });
      }, []);
  return (
    <>
      <Section className={`globalnetwork-section`}>
        <MyDiv className="container globalnetwork-container">
          <Row className="align-items-center">
            <Col lg={5} col-12 className="beebox-col mx-auto" >
              <MyDiv className="globalnetwork-content">
              <HeadingThree dataaos="slide-right">The beebox</HeadingThree>
              <HeadingTwo dataaos="slide-right">Global Network</HeadingTwo>
              <PTag dataaos="slide-up">
              In addition to packaging solutions, Beebox is deeply invested in streamlining the transportation process from point A to point B. To enhance our services, Beebox has partnered with the extensive logistics network MiPharma Global, creating a seamless connectivity platform that spans across more than 95 countries. Through this collaboration, we strive to not only offer superior packaging but also to eliminate the complexities and hassles of global transportation, ensuring a smooth and efficient journey for our customers' valuable cargo.
              </PTag>
              </MyDiv>
            </Col>
            <Col lg={7} col-12 className="beebox-col d-flex justify-content-center"  data-aos="slide-left">
            <img src={SpinningImage} alt="Global Network" className='globalnetwork-image' />
            </Col>
          </Row>
        </MyDiv>
      </Section>
    </>
  );
};

export default GlobalnetworkSection;
