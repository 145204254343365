import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './home.css';
import SmartBoxFeatureSection from './smartBoxFeatureSection';
import Banner from './banner';
import ProductServiceSection from './productServiceSection';
import AboutUsSection from './aboutUsSection';
import BeeBoxSection from './beeBoxSection';
import SustainabilitySection from './sustainabilitySection';
import ContactUsSection from './contactUsSection';
import GlobalnetworkSection from './globalNetwork';
import { Link, useLocation } from "react-router-dom";
const Home = () => {
    const columnsRef = useRef([]);
    const location = useLocation();

    useEffect(() => {
        const scrollToHash = () => {
            if (location.hash) {
                const targetElement = document.getElementById(location.hash.substring(1));
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }
        };
        setTimeout(scrollToHash, 100);
    }, [location]);
    
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    const delay = index * 2000;
                    setTimeout(() => {
                        entry.target.classList.add('visible');
                    }, delay);

                    setTimeout(() => {
                        const content = entry.target.querySelector('.column-content');
                        if (content) content.style.opacity = '1';
                    }, delay + 500);

                    setTimeout(() => {
                        const img = entry.target.querySelector('img');
                        if (img) img.style.opacity = '1';
                    }, delay + 1000);

                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        const columns = columnsRef.current;
        columns.forEach((column) => {
            if (column) {
                observer.observe(column);
            }
        });

        return () => {
            columns.forEach((column) => {
                if (column) {
                    observer.unobserve(column);
                }
            });
        };
    }, []);


    return (
        <>
            <Helmet>
                <title>Home - Beebox</title>
                <meta name="description" content="The Intelligent Pharma Transport Solution" />
                <meta name="keywords" content="Beebox" />
            </Helmet>
            <div>
                <Banner />
                {/* About Us Section */}
                <AboutUsSection />
                {/* The Bee Box Section */}
                <BeeBoxSection />
                {/* Product Service Section */}
                <ProductServiceSection />
                {/* Smart Box Feature */}
                <SmartBoxFeatureSection />
                {/* Global network Section */}
                <GlobalnetworkSection />
                {/* Sustainability Section */}
                <SustainabilitySection />
                {/* contact Us Section */}
                <ContactUsSection />
            </div>
        </>
    );
};

export default Home;
