import React from "react";
import { useEffect, useState  } from "react";
import Container from 'react-bootstrap/Container';
import { Row } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import HomeBanner from '../../assets/images/homepage-banner.jpg';
import bannergif from "../../assets/images/box-giff-final.gif";
import flight from "../../assets/images/flight.png";
import van from "../../assets/images/van.png";
import { MyDiv } from '../../common/components';

function Home() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.body.classList.add('home-layout')
    return () => {
       document.body.classList.remove('home-layout')
    }
 }, [])

  return (
    <>
    <Container fluid className="home-banner-align"  style={{
        backgroundImage: `url(${HomeBanner})`, // Use the imported image
      }}>
      <Row className="justify-content-center">
        <Col md={12} className="ban-inner-con">
        <img src={flight} alt="bannergif"  className="flightgif"/>
          <MyDiv className='banner-text text-center'>
              <h2 className="hide-for-small-only">Enhancing pharmaceutical logistics <br />through precision & innovation</h2>
              <p>Beebox ensures the seamless and secure transport of vital healthcare products worldwide.</p>
              <img src={bannergif} alt="bannergif"  className="bannergif"/>
          </MyDiv>
          <img src={van} alt="vangif"  className="vangif"/>
        </Col>
      </Row>
    </Container>
      </>

  );
}

export default Home;
