import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../assets/images/hedersvglogo.svg";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function Header({ expanded, setExpanded }) {
  const location = useLocation();
  return (
    <>
    <Navbar expanded={expanded} expand="lg" variant="dark" className="header">
      <Container  className="withcontainer">
        <Navbar.Brand to="/" as={Link}>
        <img src={Logo} className="img-responsive" alt='Logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="menu-list">
            <Nav.Link href="/" onClick={() => setExpanded(false)} className={location.pathname === '/' ? 'home-link active' : 'home-link'} >Home</Nav.Link>
            <Nav.Link href="/about-us" onClick={() => setExpanded(false)} className={location.pathname === '/about-us' ? 'active' : ''} >About Us</Nav.Link>
            <Nav.Link href="/the-beebox" onClick={() => setExpanded(false)} className={location.pathname === '/the-beebox' ? 'active' : ''}>The Beebox</Nav.Link>
            <HashLink smooth to="/#products-services" onClick={() => setExpanded(false)}>Products & Services</HashLink>
            <HashLink smooth to="/#contact-section" onClick={() => setExpanded(false)}>Hive Login</HashLink>
            <Nav.Link href="/sustainability" onClick={() => setExpanded(false)} className={location.pathname === '/sustainability' ? 'active' : ''}>Sustainability</Nav.Link>
            <HashLink smooth to="/#contact-section" onClick={() => setExpanded(false)}>Contact Us</HashLink>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default Header;