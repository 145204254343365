import { React, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section, PTag, HeadingTwo, HeadingFour, MyDiv } from "../../common/components";
import { Helmet } from "react-helmet";
import Recycle from "../../assets/images/recycle.gif"
import AOS from "aos";
import "aos/dist/aos.css";
const Sustainability = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
            delay: 1000
        });
    });
    return (
        <>
            <Helmet>
                <title>Sustainability</title>
                <meta name="description" content="In today’s world, taking the easy road and choosing the path of least resistance is no longer an acceptable option," />
                <meta name="keywords" content="Sustainability" />
            </Helmet>
            <Container fluid className="sustainability-banner-align">
                <Row className="">
                    <Col md={12}>
                        <Container>
                            <MyDiv className='banner-text'>
                                <HeadingFour >SUSTAINABILITY</HeadingFour>
                            </MyDiv>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Section className="common-content-section">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <MyDiv className="aboutus-content">
                                <HeadingTwo className="common-h2">Commitment to Sustainability</HeadingTwo>
                                <PTag className="common-p">
                                    In today’s world, taking the easy road and choosing the path of least resistance is no longer an acceptable option, especially from a sustainability perspective. At Beebox GmbH, we understand the significant role we play in shaping a more sustainable future, and we recognize that our customers share this responsibility as well.
                                </PTag>
                            </MyDiv>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="sustainability-reusable-section">
                <Row>
                    <MyDiv className="column left" dataaos="fade-in"></MyDiv>
                    <MyDiv className="column righ text-align col-lg-4">
                        <HeadingTwo className="common-h2">Durable, Reusable Packaging</HeadingTwo>
                        <PTag className="common-p">Together, we can drive meaningful change by embracing sustainable practices in our packaging and logistics solutions. We are committed to innovating and implementing strategies that not only protect your valuable products but also minimize environmental impact. By aligning our efforts with those of our customers, we aim to create a positive ripple effect that contributes to a healthier planet for future generations.
                        </PTag>
                        <PTag className="common-p">Our reusable passive packaging solutions are designed for exceptional durability, capable of lasting up to 5 years or facilitating 350 individual shipments. This longevity not only enhances the efficiency of your transportation needs but also significantly reduces waste.
                        </PTag>
                    </MyDiv>
                </Row>
            </Section>
            <Section className="beebox-transport">
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={6}>
                            <HeadingTwo className="common-h2">Recycling for a Sustainable Future</HeadingTwo>
                            <PTag className="common-p">
                                Once the life cycle of our packaging solutions is complete, we prioritize sustainability by reusing and repurposing the materials for other essential tools, such as phase change material (PCM) stands. This commitment to repurposing reflects our dedication to a circular economy, ensuring that we maximize the value of our packaging while minimizing environmental impact.
                            </PTag>
                            <PTag className="common-p">
                                We have a complete recycling and repurposing handbook available for customers on request.
                            </PTag>
                        </Col>
                        <Col lg={6} className="d-flex justify-content-center align-items-center">
                            <img src={Recycle} alt="Beebox Transport" />
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Sustainability;
