import { React, useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section, PTag, HeadingTwo, HeadingSix, HeadingFive, HeadingFour, MyDiv, SpanTag } from "../../common/components";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import GMBHImage from "../../assets/images/beebox-gmbh.png"
import flight from "../../assets/images/flight.png";
import van from "../../assets/images/van.png";
const AboutUs = () => {
    const [showVision, setShowVision] = useState(false);
    useEffect(() => {
        let visionTimer = "";
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
      
        if (window.innerWidth > 768) {
           
        visionTimer = setTimeout(() => {
            setShowVision(true);
        }, 4000);
        }

        if (window.innerWidth < 768) {
            visionTimer = setTimeout(() => {
                setShowVision(true);
            }, 0);
        }


        return () => {
            clearTimeout(visionTimer);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>About Us - Beebox</title>
                <meta name="description" content="At Beebox GmbH, we are dedicated to revolutionizing the transportation of pharmaceuticals, biotech products," />
                <meta name="keywords" content="About Us" />
            </Helmet>
    
            <Container fluid className="aboutus-banner-align">
                <Row className="">
                    <Col md={12}>
                        <Container>
                        <img src={flight} alt="bannergif"  className="flightgif"/>
                            <MyDiv className='banner-text'>
                                <HeadingFour className="">ABOUT US</HeadingFour>
                            </MyDiv>
                            <img src={van} alt="vangif"  className="vangif"/>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Section className="aboutus-content-section">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <MyDiv className="aboutus-content">
                                <HeadingTwo className="common-h2">Welcome to Beebox GmbH</HeadingTwo>
                                <PTag className="common-para">
                                    At Beebox GmbH, we are dedicated to revolutionizing the transportation of pharmaceuticals, biotech products, clinical trials, and other high-value sensitive items. Our mission is to enhance visibility, transparency, and temperature control throughout the shipping process, ensuring that your products arrive safely and in optimal condition.
                                </PTag>
                                <PTag className="common-para">
                                    By integrating premium packaging materials, advanced phase change materials (PCM), cutting-edge Internet of Things (IoT) technology, and innovative security measures, we provide our customers with a reliable and secure packaging solution. Trust Beebox GmbH to safeguard your valuable shipments, allowing you to focus on what matters most—your business. Explore our solutions today and discover how we can elevate your logistics experience.
                                </PTag>
                            </MyDiv>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="aboutus-vision-section">
                <Container>
                    <Row>
                        <Col lg={12}>
                            {showVision && (
                                <>
                                    <MyDiv dataaos="fade-up" className="vision">
                                        <HeadingFive>VISION</HeadingFive>
                                        <PTag className="common-para">
                                            At Beebox GmbH, we envision a future where sustainable multiuse packaging systems play a pivotal role in supporting a circular economy. We are committed to developing innovative solutions that meet the needs of our customers while minimizing environmental impact.
                                        </PTag>
                                    </MyDiv>
                                </>
                            )}
                            <MyDiv className="mission">
                                <HeadingFive>MISSION</HeadingFive>
                                <PTag className="common-para">
                                    Our mission is to tackle the staggering $15 billion loss of pharmaceutical products caused by temperature excursions. We strive to provide reliable and effective packaging solutions that ensure the integrity and safety of sensitive products throughout their transportation.
                                </PTag>
                            </MyDiv>
                            {showVision && (
                                <>
                                    <MyDiv className="values" dataaos="fade-down" >
                                        <HeadingFive>VALUES</HeadingFive>
                                        <PTag className="common-para">
                                            <SpanTag>Trust:</SpanTag> We prioritize building trust with our clients by consistently delivering on our promise to safeguard highly sensitive products.
                                        </PTag>
                                        <PTag className="common-para">
                                            <SpanTag>Innovation:</SpanTag> We embrace a culture of continuous innovation, always seeking to push the boundaries of what is possible in packaging and logistics.
                                        </PTag>
                                        <PTag className="common-para">
                                            <SpanTag>Teamwork:</SpanTag> We recognize that our success is built on collaboration. Our dedicated team works together to overcome challenges and achieve our goals, making us stronger as a whole.
                                        </PTag>
                                    </MyDiv>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="aboutus-gmbh">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <PTag>
                                <SpanTag>Together,</SpanTag> these principles guide us in our quest to enhance the transportation of high-value sensitive products while promoting sustainability and reliability.
                            </PTag>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-center right-content">
                        <Col lg={6}>
                            <img src={GMBHImage} alt="Beebox GMBH" />
                        </Col>
                        <Col lg={6}>
                            <PTag>
                                “At Beebox GmbH, we believe that continuous improvement is the cornerstone of delivering superior products and services. Our commitment to innovation drives us to not only enhance our own capabilities but also seek strategic collaborations with market leaders. By partnering with the best in the industry, we aim to create comprehensive solutions that meet and exceed our customers' expectations. Together, we are dedicated to refining our offerings and providing you with the most effective and reliable packaging and transportation solutions for your high-value sensitive products.”
                            </PTag>
                            <HeadingSix>
                                Jorn Taucke – Managing Director
                            </HeadingSix>
                        </Col>
                    </Row>
                </Container>
            </Section>

        </>
    );
};

export default AboutUs;
