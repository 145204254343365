import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from "react-bootstrap";
import { MyDiv, HeadingThree, HeadingTwo, PTag, Section } from '../../common/components';
import SustainabilityImage from '../../assets/images/sustainable-img.png';

const SustainabilitySection = () => {
    const susContentRef = useRef(null);
    const susImageRef = useRef(null);
    const [backgroundVisible, setBackgroundVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const delay = 5000; 
                    const elementsToAnimate = [susContentRef.current, susImageRef.current];
                    elementsToAnimate.forEach((element, index) => {
                        if (element) {
                            setTimeout(() => {
                                element.classList.add('visible');
                                if (index === elementsToAnimate.length - 1) {
                                    setTimeout(() => {
                                        setBackgroundVisible(true);
                                    }, delay);
                                }
                            }, index * 100);
                        }
                    });

                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        const contentElement = susContentRef.current;
        const imageElement = susImageRef.current;

        if (contentElement) {
            observer.observe(contentElement);
        }
        if (imageElement) {
            observer.observe(imageElement);
        }

        return () => {
            if (contentElement) {
                observer.unobserve(contentElement);
            }
            if (imageElement) {
                observer.unobserve(imageElement);
            }
        };
    }, []);

  return (
    <>
      <Section className={`sustainability-section ${backgroundVisible ? 'show-background' : ''}`} id="sustainability-section" >
        <MyDiv className="container sustainability-container"  >
          <Row className="align-items-center">
            <Col lg={6} col-12 className="beebox-col mx-auto" >
              <MyDiv className="sustainability-content" ref={susContentRef}>
              <HeadingThree>Sustainability at Beebox</HeadingThree>
              <HeadingTwo>Shaping the Future of Pharma Transport</HeadingTwo>
              <PTag>
              At Beebox GmbH, we prioritize sustainability through the use of reusable and multi-use systems. None of our products are designed for single-use disposal; instead, we focus on a circular economy approach by reusing, refurbishing, renovating, or recycling products that have reached the end of their lifecycle. Our dedication to sustainability is reflected also through the use of a carbon calculator (GLEC Approved). Beebox customers have access to a tool that enables them to measure, report, and assess environmentally friendly transportation options. We believe that impactful change starts today, and Beebox is dedicated to taking tangible actions in the realm of sustainability, rather than just offering mere rhetoric.
              </PTag>
              </MyDiv>
            </Col>
            <Col lg={6} col-12 className="beebox-col">
            <img src={SustainabilityImage} alt="Sustainability" className='sustainability-image' ref={susImageRef} />
            </Col>
          </Row>
        </MyDiv>
      </Section>
    </>
  );
};

export default SustainabilitySection;
