import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from "react-bootstrap";
import { MyDiv, HeadingThree, HeadingTwo, PTag, Section } from '../../common/components';
import AboutUsImage from '../../assets/images/aboutus-anime.gif';

const AboutUsSection = () => {
    const aboutContentRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry, index) => {
            if (entry.isIntersecting) {
                const delay = index * 5000; 
                setTimeout(() => {
                    entry.target.classList.add('visible'); 
                }, delay);

                setTimeout(() => {
                    const content = entry.target.querySelector('.about-content');
                    if (content) content.style.opacity = '1'; 
                }, delay + 10000); 

                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.1 });
      const ulElement = aboutContentRef.current;
      if (ulElement) {
        observer.observe(ulElement);
      }
  
      return () => {
        if (ulElement) {
          observer.unobserve(ulElement);
        }
      };
    }, []);

  return (
    <>
      <Section className="about-us-section" id="about-us-section">
        <MyDiv className="container">
          <Row className="align-items-center">
            <Col lg={4} xs={12} className="about-col mx-auto" >
              <MyDiv className="about-content" ref={aboutContentRef}>
              <HeadingThree>ABOUT US</HeadingThree>
              <HeadingTwo>Best Strategy <br />
              And Technology</HeadingTwo>
              <PTag>
              At Beebox GmbH, our goal is to enhance visibility, transparency, and temperature control for the transportation of pharmaceuticals, biotech products, clinical trials, and other high value sensitive products. By combining top-tier packaging materials, phase change materials (PCM), Internet of Things (IoT) technology, and innovative security measures, we offer customers a reliable packaging solution for safeguarding their valuable shipments.
              </PTag>
              </MyDiv>
            </Col>
            <Col lg={8} xs={12} className="about-col">
            <img src={AboutUsImage} alt="About Us" className='about-us-image' />
            </Col>
          </Row>
        </MyDiv>
      </Section>
    </>
  );
};

export default AboutUsSection;
