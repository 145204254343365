import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { sendForm } from '@emailjs/browser';
import { MyDiv, HeadingThree, Section, HeadingOne } from '../../common/components';

const ContactUsSection = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const formRef = useRef();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const onSubmit = async (data) => {

    try {
      await sendForm(
        'service_5wso5uc',
        'template_g4qjhgt',
        formRef.current,
        'RmKjNeIxQIgogJM1I'
      );
 
      reset();
      setMessage('Email sent successfully!');
      setMessageType('success');
      setTimeout(() => {
        setMessage('');
        setMessageType('');
      }, 5000);
    } catch (error) {
      console.error('Failed to send email. Error:', error);
      setMessage('Failed to send email. Please try again later.');
      setMessageType('error');
      setTimeout(() => {
        setMessage('');
        setMessageType('');
      }, 5000);
    }
  };

  return (
    <>
      <Section className="contact-section" id="contact-section">
        <MyDiv className="container">
          <Row className="align-items-center">
            <Col lg={6} xs={12} className="contact-col mx-auto d-flex flex-column" >
              <HeadingOne>Beeboxes are available to Buy,<br />
                Lease and Pay Per Use</HeadingOne>
              <MyDiv className="contact-form" id="contact-form">
                <HeadingThree>Contact Us</HeadingThree>
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                      id="fullName"
                      placeholder="Full Name"
                      {...register('fullName', {
                        required: 'Full Name is required',
                        pattern: {
                          value: /^[A-Za-z\s]+$/i,
                          message: "Only letters and spaces are allowed"
                        },
                        maxLength: {
                          value: 100,
                          message: "Full name must be less than or equal to 100 characters"
                        }
                      })}
                    />
                    <MyDiv className="invalid-div"></MyDiv>
                    <MyDiv className="invalid-feedback"> {errors.fullName ? errors.fullName.message : ''}</MyDiv>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                      id="phoneNumber"
                      placeholder="Phone Number"
                      {...register('phoneNumber', {
                        required: 'Phone number is required',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: 'Phone number must be exactly 10 digits',
                        },
                      })}
                    />
                    <div className="invalid-feedback">{errors.phoneNumber ? errors.phoneNumber.message : ''}</div>
                  </div>

                  <div className="form-group">

                    <input
                      type="email"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      id="email"
                      placeholder="E-mail"
                      {...register('email', {
                        required: 'E-mail is required',
                        maxLength: {
                          value: 255,
                          message: "E-mail must be less than or equal to 255 characters"
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Email address is invalid',
                        },
                      })}
                    />
                    <div className="invalid-feedback"> {errors.email ? errors.email.message : ''}</div>
                  </div>

                  <div className="form-group">

                    <textarea
                      className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                      id="message"
                      rows="4"
                      placeholder="Message"
                      {...register('message', {
                        required: 'Message is required', maxLength: {
                          value: 500,
                          message: "Message must be less than or equal to 500 characters"
                        }
                      })}
                    ></textarea>
                    <div className="invalid-feedback">{errors.message ? errors.message.message : ''}</div>
                  </div>

                  <button type="submit" className="btn btn-primary">Submit Now</button>
                </form>
                {/* Message Display Section */}
                {message && (
                  <div className={`mt-3 alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`}>
                    {message}
                  </div>
                )}
              </MyDiv>
            </Col>
          </Row>
        </MyDiv>
      </Section>
    </>
  );
};

export default ContactUsSection;
