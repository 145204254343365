import React, { Component, forwardRef } from 'react';

export const MyDiv = forwardRef((props, ref) => {
    const { className = '', children, dataaos, dataaosdelay } = props;
    return <div className={className} ref={ref} data-aos={dataaos} data-aos-delay={dataaosdelay}>{children}</div>;
});

export const Section = forwardRef((props, ref) => {
  const { className = '', children,id='' } = props;
  return <section className={className} ref={ref} id={id}>{children}</section>;
});


export const PTag = forwardRef((props, ref) => {
    const { className = '', children, dataaos} = props;
    return <p className={className} ref={ref} data-aos={dataaos}>{children}</p>;
});

export class LabelTag extends Component {
  render() {
    let LabelTag = null,
      className = ''

    if(this.props.className !== undefined){
        className = this.props.className
    }
    return <label className={className}>{this.props.children}</label>
  }
}
export class SpanTag extends Component {
  render() {
      let SpanTag = null,
      className = ''

    if(this.props.className !== undefined){
        className = this.props.className
    }
    return <span className={className}>{this.props.children}</span>
  }
}
export class HeadingOne extends Component {
  render() {
      let HeadingOne = null,
      className = ''

    if(this.props.className !== undefined){
        className = this.props.className
    }
    return <h1 className={className}>{this.props.children}</h1>
  }
}

export const HeadingTwo = forwardRef((props, ref) => {
    const { className = '', children, dataaos } = props;
    return <h2 className={className} ref={ref} data-aos={dataaos}>{children}</h2>;
});

export const HeadingThree = forwardRef((props, ref) => {
    const { className = '', children, dataaos } = props;
    return <h3 className={className} ref={ref} data-aos={dataaos}>{children}</h3>;
});


export const HeadingFour = forwardRef((props, ref) => {
  const { className = '', children } = props;
  return <h4 className={className} ref={ref}>{children}</h4>;
});


export class HeadingFive extends Component {
  render() {
      let HeadingFive = null,
      className = ''

    if(this.props.className !== undefined){
        className = this.props.className
    }
    return <h5 className={className}>{this.props.children}</h5>
  }
}


export class HeadingSix extends Component {
    render() {
        let HeadingSix = null,
        className = ''
  
      if(this.props.className !== undefined){
          className = this.props.className
      }
      return <h6 className={className}>{this.props.children}</h6>
    }
  }
  


export const UlTag = forwardRef((props, ref) => {
    const { className = '', children } = props;
    return <ul className={className} ref={ref}>{children}</ul>;
});

export class LiTag extends Component {
    render() {
        let LiTag = null,
        className = ''
  
      if(this.props.className !== undefined){
          className = this.props.className
      }
      return <li className={className}>{this.props.children}</li>
    }
  }

export default {MyDiv, PTag, Section, SpanTag, LabelTag, HeadingOne, HeadingTwo, HeadingThree, HeadingFour, HeadingFive, HeadingSix, UlTag, LiTag};