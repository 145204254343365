import { React } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section, PTag, HeadingTwo, HeadingFour, MyDiv } from "../../common/components";
import { Helmet } from "react-helmet";
import Werehouse from "../../assets/images/warehouse.png"
import Temprature from "../../assets/images/temprature.gif"

const TheBeebox = () => {
    return (
        <>
            <Helmet>
                <title>The Beebox</title>
                <meta name="description" content="With inspiration from the industrious nature of bees, known for their meticulous transport of vital resources to" />
                <meta name="keywords" content="The Beebox" />
            </Helmet>
            <Container fluid className="thebeebox-banner-align">
                <Row className="">
                    <Col md={12}>
                        <Container>
                            <MyDiv className='banner-text'>
                                <HeadingFour >THE BEEBOX</HeadingFour>
                            </MyDiv>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Section className="common-content-section">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <MyDiv className="aboutus-content">
                                <HeadingTwo className="common-h2">Efficient and Secure Global Logistics</HeadingTwo>
                                <PTag className="common-p">
                                With inspiration from the industrious nature of bees, known for their meticulous transport of vital resources to their hives for the well-being of their colony. This powerful analogy reflects our commitment to efficiently and seamlessly moving packages across the globe.
                                </PTag>
                                <PTag className="common-p">
                                Our temperature-controlled boxes are designed not only to safeguard against temperature excursions but also to ensure complete transparency throughout the logistics supply chain. Equipped with both external and internal IoT devices, our packaging solutions continuously monitor critical parameters including light, shock, humidity, and location. This comprehensive data collection allows us to maintain the highest standards of product integrity during transportation.
                                </PTag>
                            </MyDiv>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="beebox-ship-section">
                <Container>
                    <Row className="justify-content-center align-items-center ">
                        <Col lg={6}>
                            <MyDiv className="content">
                                <HeadingTwo className="common-h2">Proactive Shipment Management</HeadingTwo>
                                <PTag className="common-p">
                                We support our customers throughout the entire supply chain with our innovative Pay Per Use Business Model. This approach empowers customers with timely updates and alerts based on the milestones they set, enabling proactive management of their shipments. Our goal is to identify potential incidents before they occur, allowing us to take swift action whether on the tarmac, in the warehouse, or during delivery. 
                                </PTag>
                            </MyDiv>
                        </Col>
                        <Col lg={6}>
                        <img src={Werehouse} alt="Werehouse"/>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="beebox-transport">
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={6}>
                            <img src={Temprature} alt="Beebox Transport" />
                        </Col>
                        <Col lg={6}>
                            <HeadingTwo className="common-h2">Ensuring Safe Transport</HeadingTwo>
                            <PTag className="common-p">
                            Transporting high-value sensitive products involves inherent risks. That's why we offer lane risk assessments designed to provide our customers with a sense of assurance throughout their logistics process. 
                            </PTag>
                            <PTag className="common-p">
                            Our comprehensive assessments analyze various factors such as temperature stability, summer and winter modalities, potential hazards, and logistical challenges specific to each route. By identifying and evaluating these risks, we equip our customers with the information and confidence they need to make informed decisions. With our lane risk assessments, you can trust that your shipments are not only effectively monitored but also strategically managed to mitigate potential challenges along the way. This proactive approach ensures the safety and integrity of your valuable products from start to finish.
                            </PTag>
                        </Col>
                    </Row>
                </Container>
            </Section>

        </>
    );
};

export default TheBeebox;
