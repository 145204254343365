// src/components/Home.js

import React, { useEffect, useRef } from 'react';
import productImageOne from '../../assets/images/product-service-img-3.png';
import productImageTwo from '../../assets/images/product-service-img-2.png';
import productImageThree from '../../assets/images/product-service-img-1.png';
import { MyDiv, HeadingOne, HeadingTwo, HeadingThree, PTag, Section, HeadingFour, UlTag, LiTag } from '../../common/components';

const ProductServiceSection = () => {
    const columnsRef = useRef([]);
    const headingRef = useRef([]);
   
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {

                    const elementsToAnimate = headingRef.current;
                    if (elementsToAnimate) {
                        setTimeout(() => {
                            elementsToAnimate.classList.add('visible'); 
                        }, 100); 
                    }

                    const delay = index * 1200; 
                    setTimeout(() => {
                        entry.target.classList.add('visible'); 
                    }, delay);

                    setTimeout(() => {
                        const content = entry.target.querySelector('.column-content');
                        if (content) content.style.opacity = '1'; 
                    }, delay + 1000); 

                    setTimeout(() => {
                        const img = entry.target.querySelector('img');
                        if (img) img.style.opacity = '1';
                    }, delay + 1500);

                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        const columns = columnsRef.current;
        columns.forEach((column) => {
            if (column) {
                observer.observe(column);
            }
        });
        
        return () => {
            columns.forEach((column) => {
                if (column) {
                    observer.unobserve(column);
                }
            });
        };
    }, []);

    return (
        <>
                <Section className="product-section" id="products-services">
                     <MyDiv ref={headingRef} className="heading">   
                    <HeadingTwo>Product & Services</HeadingTwo>
                    <HeadingFour>Advantages of Beebox 24 L & 44 L</HeadingFour>
                    </MyDiv>
                    <MyDiv className='product-section-flex'>
                        <MyDiv className="column" ref={el => columnsRef.current[0] = el}>
                            <MyDiv className='img-div'>
                                <img src={productImageOne} alt="Product" className='product-service-image' />
                            </MyDiv>
                            <MyDiv className="column-content">
                                <HeadingThree>Reusable System</HeadingThree>
                              
                                <UlTag>
                                    <LiTag>You increase the sustainability of your business.</LiTag>
                                    <LiTag>Increase your profitability.</LiTag>
                                    <LiTag>No equipment management or storage required, just in time delivery.</LiTag>
                                </UlTag>
                            </MyDiv>
                        </MyDiv>
                        <MyDiv className="column" ref={el => columnsRef.current[1] = el}>
                            <MyDiv className='img-div'>
                                <img src={productImageTwo} alt="Product" className='product-service-image' />
                            </MyDiv>
                            <MyDiv className="column-content">
                                <HeadingThree>PCM</HeadingThree>
                               
                                <UlTag>
                                    <LiTag>Offer increased safety to maintain required temperature thresholds.</LiTag>
                                    <LiTag>Simplify SOP’s and reduce errors by using all season pack-outs with PCM.</LiTag>
                                    <LiTag>Keep things simple by using one box design for temperatures 2-8°C, 15-25°C</LiTag>
                                    <LiTag>Precondition the box together with the PCMs at 5° in your own pharma warehouse</LiTag>
                                    <LiTag>Up to 140h runtime (ISTA 7D)</LiTag>
                                </UlTag>
                            </MyDiv>
                        </MyDiv>
                        <MyDiv className="column" ref={el => columnsRef.current[2] = el}>
                            <MyDiv className='img-div'>
                                <img src={productImageThree} alt="Product" className='product-service-image' />
                            </MyDiv>
                            <MyDiv className="column-content">
                                <HeadingThree>IoT</HeadingThree>
                               
                                <UlTag>
                                    <LiTag>Prevent or detect theft with authorized access only.</LiTag>
                                    <LiTag>No more downloading of USB-data loggers. Automated and live data 24/7.</LiTag>
                                    <LiTag>Simplified decision-making based on live data at one click instead of comparing endless numbers after the fact.</LiTag>
                                    <LiTag>Find and eliminate the weak points in your supply chain and make each hand-over point visible.</LiTag>
                                    <LiTag>Identify problematic routes</LiTag>
                                    <LiTag>Live access to the status of all shipments.</LiTag>
                                </UlTag>
                            </MyDiv>
                        </MyDiv>
                    </MyDiv>
                </Section>
        </>
    );
};

export default ProductServiceSection;
