import React from "react";
import { Col, Row } from "react-bootstrap";
import { Section, PTag, HeadingTwo, HeadingOne, HeadingThree, HeadingFour, UlTag, LiTag, MyDiv } from "../../common/components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Imprint = () => {
    return (
        <>
        <Helmet>
        <title>Imprint - Beebox</title>
        <meta name="description" content="The European Commission provides a platform for online dispute resolution (OS) Our email address can be found above in the imprint." />
        <meta name="keywords" content="Imprint" />
        </Helmet>
            <Section className="common-sec">
                <MyDiv className="container breadcrumb-container">
                    <Row className="justify-content-end">
                        <Col lg="auto">
                            <PTag>
                                <Link to="/" as={Link}>Home</Link> &gt; Imprint
                            </PTag>
                        </Col>
                    </Row>
                </MyDiv>
                <MyDiv className="common-banner imprint-banner">
                    <MyDiv className="container">
                        <Row>
                            <Col lg={12}>
                                <MyDiv className="banner-content">
                                    <HeadingOne>Imprint</HeadingOne>
                                </MyDiv>
                            </Col>
                        </Row>
                    </MyDiv>
                </MyDiv>
                <MyDiv className="container">
                    <Row>
                        <Col lg={12}>

                            <HeadingThree>Information according to § 5 TMG:</HeadingThree>
                            {/* <PTag>We are pleased that you are interested in our website. Protecting your privacy is very important to us. Below we provide detailed information about how we handle your data.</PTag>
                            <br />
                            <HeadingThree>Responsible Party</HeadingThree> */}


                            <PTag>Beebox GmbH </PTag>


                            <PTag>Donaustr. 4b</PTag>

                            <PTag>65451 Kelsterbach</PTag>

                            <PTag>Germany</PTag>

                            <br />
                            <HeadingThree>Represented by:</HeadingThree>
                            <PTag>Joern Taucke, David Keen</PTag>

                            <br />
                            <HeadingThree>Contact:</HeadingThree>
                            <PTag>Phone: <a href="tel:+162 909 33 92">+162 909 33 92</a></PTag>
                            <PTag>Email: <a href="mailto:hello@beebox360.com">hello@beebox360.com</a></PTag>

                            <br />
                            <HeadingThree>Register Entry:</HeadingThree>
                            <PTag>Entry in the commercial register. </PTag>
                            <PTag>Register Court: Amtsgericht Darmstadt</PTag>
                            <PTag>Register Number: HRB 106223</PTag>
                            <br />
                            <HeadingThree>VAT ID:</HeadingThree>
                            <PTag>Value-added tax identification number according to §27 a Value Added Tax Act: DE 3684 065 68
                            </PTag>
                            <br />
                            <HeadingThree>Responsible for content according to § 55 para. 2 RStV:</HeadingThree>

                            <PTag>Beebox GmbH </PTag>


                            <PTag>Donaustr. 4b</PTag>

                            <PTag>65451 Kelsterbach</PTag>

                            <PTag>Germany</PTag>



                            <br />
                            <HeadingThree>Dispute Resolution:</HeadingThree>
                            <PTag>The European Commission provides a platform for online dispute resolution (OS) Our email address can be found above in the imprint. </PTag>

                            <PTag>We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.</PTag>


                            <br />
                            <HeadingThree>Liability for Content:</HeadingThree>
                            <PTag>As a service provider, we are responsible for our own content on these pages in accordance with § 7 para.1 TMG under the general laws. According to §§ 8 to 10 TMG, however, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.
                            </PTag>
                            <br />
                            <PTag>Obligations to remove or block the use of information under general laws remain unaffected. However, liability in this regard is only possible from the time of knowledge of a specific infringement. Upon becoming aware of such legal violations, we will remove this content immediately.
                            </PTag>
                            <br />
                            <HeadingThree>Liability for Links:</HeadingThree>
                            <PTag>Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking.
                            </PTag>
                            <br />
                            <PTag>A permanent content control of the linked pages is not reasonable without concrete evidence of a violation of the law. Upon becoming aware of legal violations, we will remove such links immediately.    </PTag>
                            <br />
                            <HeadingThree>Copyright:</HeadingThree>
                            <PTag>The content and works created by the site operators on these pages are subject to German copyright law. The duplication, processing, distribution, and any kind of exploitation outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use.</PTag>


                        </Col>
                    </Row>
                </MyDiv>

            </Section>
        </>
    );
};

export default Imprint;
