import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import featureVideoBg from '../../assets/videos/feature-video.mp4';
import { Col, Row } from "react-bootstrap";
import { MyDiv, HeadingThree, HeadingTwo, PTag, UlTag, LiTag, Section } from '../../common/components';

const SmartBoxFeatureSection = () => {
    const ulRef = useRef(null);
    const headingThreeRef = useRef(null);
    const headingTwoRef = useRef(null);
    const pTagRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        console.log("entries",entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {

            const elementsToAnimate = [headingThreeRef.current, headingTwoRef.current, pTagRef.current];
            elementsToAnimate.forEach((element, index) => {
                if (element) {
                    setTimeout(() => {
                        element.classList.add('fade-in');
                    }, index * 100);
                }
            });

            const liElements = ulRef.current.querySelectorAll('li');
            liElements.forEach((li, index) => {
              li.style.setProperty('--index', index); // Set CSS variable for animation
              setTimeout(() => {
                li.classList.add('visible');
              }, index * 500);
            });

            
            observer.unobserve(entry.target);
          }
        });
      }, { threshold: 0.1 });
  
      const ulElement = ulRef.current;
      if (ulElement) {
        observer.observe(ulElement);
      }
  
      return () => {
        if (ulElement) {
          observer.unobserve(ulElement);
        }
      };
    }, []);

  return (
    <>
      <Section className="smart-box-feature" id="smart-box-feature">
        <video className="background-video" autoPlay loop muted>
          <source src={featureVideoBg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay"></div>
        <MyDiv className="container">
          <Row>
            <Col lg={6} md={6} sm={6} xs={12} className="feature-col">
              <HeadingThree ref={headingThreeRef}>IOT</HeadingThree>
              <HeadingTwo ref={headingTwoRef}>IoT – SMART BOX Features</HeadingTwo>
              <PTag ref={pTagRef}>
                First reusable passive transport system in the market with complete IoT functionality.
              </PTag>
             
              <UlTag ref={ulRef}>
                <LiTag>Global positioning</LiTag>
                <LiTag>Automated reading of the data after opening</LiTag>
                <LiTag>Authorization application for closing / opening</LiTag>
                <LiTag>Outside / inside temperature reading</LiTag>
                <LiTag>Light detection</LiTag>
                <LiTag>Shock / Tilt</LiTag>
                <LiTag>Real time Location / Geofencing</LiTag>
                <LiTag>Automated data analysis</LiTag>
                <LiTag>Humidity</LiTag>
                <LiTag>Robust design</LiTag>
                <LiTag>2 Border PCM coolant (if needed)</LiTag>
              </UlTag>
            </Col>
          </Row>
        </MyDiv>
      </Section>
    </>
  );
};

export default SmartBoxFeatureSection;
