import React from "react";
import { Col, Row } from "react-bootstrap";
import { Section, PTag, HeadingOne, HeadingThree, UlTag, LiTag, MyDiv } from "../../common/components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const currentYear = new Date().getFullYear();
const PrivacyPolicy = () => {
    return (
        <>
        <Helmet>
        <title>Privacy Policy - Beebox</title>
        <meta name="description" content="We are pleased that you are interested in our website. Protecting your privacy is very important to us." />
        <meta name="keywords" content="Privacy Policy" />
        </Helmet>
            <Section className="common-sec">
                <MyDiv className="container breadcrumb-container">
                <Row className="justify-content-end">
                <Col  lg="auto">
                    <PTag>
                        <Link to="/" as={Link}>Home</Link> &gt; Privacy Policy
                    </PTag>
                </Col>
                </Row>
                </MyDiv>
                <MyDiv className="common-banner">
                    <MyDiv className="container">
                        <Row>
                            <Col lg={12}>
                                <MyDiv className="banner-content">
                                    <HeadingOne>Privacy Policy</HeadingOne>
                                </MyDiv>
                            </Col>
                        </Row>
                    </MyDiv>
                </MyDiv>
                <MyDiv className="container">
                    <Row>
                        <Col lg={12}>

                            <HeadingThree>Interpretation</HeadingThree>
                            <PTag>We are pleased that you are interested in our website. Protecting your privacy is very important to us. Below we provide detailed information about how we handle your data.</PTag>
                            <br />
                            <HeadingThree>Responsible Party</HeadingThree>


                            <PTag>The party responsible for data processing on this website is </PTag>

                            <PTag>Beebox GmbH</PTag>

                            <PTag>Donaustr. 4b</PTag>

                            <PTag>65451 Kelsterbach</PTag>

                            <PTag><a href="mailto:hello@beebox360.com">hello@beebox360.com</a></PTag>

                            <br />
                            <HeadingThree>Collection and Storage of Personal Data and the Nature and Purpose of Their Use</HeadingThree>
                            <PTag><b>a)</b> When visiting the website: When you access our website, the browser used on your device automatically sends information to the server of our website. This information is temporarily stored in a so-called log file. The following information is collected without your intervention and stored until automated deletion: </PTag>
                            <UlTag>
                                <LiTag>
                                    IP address of the requesting computer,
                                </LiTag>
                                <LiTag>
                                    Date and time of access,
                                </LiTag>
                                <LiTag>
                                    Name and URL of the retrieved file,
                                </LiTag>
                                <LiTag>
                                    Website from which access is made (referrer URL),
                                </LiTag>
                                <LiTag>
                                    Browser used and, if applicable, the operating system of your computer as well as the name of your access provider.
                                </LiTag>
                            </UlTag>
                            <br />
                            <PTag>The mentioned data is processed by us for the following purposes:</PTag>
                            <UlTag>
                                <LiTag>
                                    Ensuring a smooth connection setup of the website,
                                </LiTag>
                                <LiTag>
                                    Ensuring comfortable use of our website,
                                </LiTag>
                                <LiTag>
                                    Evaluation of system security and stability, and
                                </LiTag>
                                <LiTag>
                                    For other administrative purposes.
                                </LiTag>
                            </UlTag>
                            <br />
                            <PTag>The legal basis for data processing is Art. 6 para. 1 sentence 1 lit. f GDPR. Our legitimate interest follows from the purposes listed above for data collection. In no case do we use the collected data for the purpose of drawing conclusions about you personally.</PTag>
                            <br />
                            <PTag>
                            <b>b)</b> When using our contact form: For questions of any kind, we offer you the opportunity to contact us via a form provided on the website. It is necessary to provide a valid email address so that we know who the request came from and to be able to respond to it. Further information can be provided voluntarily.
                            </PTag>
                            <br />
                            <PTag>Data processing for the purpose of contacting us is carried out in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR based on your voluntarily given consent.</PTag>
                            <br />
                            <HeadingThree>Disclosure of Data</HeadingThree>
                            <PTag>Your personal data will not be transferred to third parties for purposes other than those listed below. We only share your personal data with third parties if:</PTag>
                            <UlTag>
                                <LiTag>
                                    You have given your express consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR,
                                </LiTag>
                                <LiTag>
                                    The disclosure is necessary for the assertion, exercise or defense of legal claims in accordance with Art. 6 para. 1 sentence 1 lit. f GDPR and there is no reason to assume that you have an overriding legitimate interest in not disclosing your data,
                                </LiTag>
                                <LiTag>
                                    There is a legal obligation for disclosure in accordance with Art. 6 para. 1 sentence 1 lit. c GDPR, and
                                </LiTag>
                                <LiTag>
                                    This is legally permissible and required for the processing of contractual relationships with you in accordance with Art. 6 para. 1 sentence 1 lit. b GDPR.
                                </LiTag>
                            </UlTag>
                            <br />
                            <HeadingThree>Rights of Data Subjects</HeadingThree>
                            <PTag>You have the right to:</PTag>
                            <UlTag>
                                <LiTag>
                                    Request information about your personal data processed by us in accordance with Art. 15 GDPR. In particular, you can request information about the processing purposes, the category of personal data, the categories of recipients to whom your data has been or will be disclosed, the planned storage period, the existence of a right to rectification, deletion, restriction of processing or objection, the existence of a right to lodge a complaint, the origin of your data, if it was not collected by us, as well as the existence of automated decision-making including profiling and, if applicable, meaningful information about its details;
                                </LiTag>
                                <LiTag>
                                    Request the correction of incorrect or incomplete personal data stored by us without delay in accordance with Art. 16 GDPR;

                                </LiTag>
                                <LiTag>
                                    Request the deletion of your personal data stored by us in accordance with Art. 17 GDPR, unless the processing is necessary for the exercise of the right to freedom of expression and information, for the fulfillment of a legal obligation, for reasons of public interest or for the assertion, exercise or defense of legal claims;
                                </LiTag>
                                <LiTag>
                                    Request the restriction of the processing of your personal data in accordance with Art. 18 GDPR, insofar as the accuracy of the data is disputed by you, the processing is unlawful, but you refuse its deletion and we no longer need the data, but you need it for the assertion, exercise or defense of legal claims or you have objected to the processing in accordance with Art. 21 GDPR;

                                </LiTag>
                                <LiTag>
                                    Receive your personal data that you have provided to us in a structured, common and machine-readable format or to request the transfer to another responsible party in accordance with Art. 20 GDPR;

                                </LiTag>
                                <LiTag>
                                    Revoke your consent given to us at any time in accordance with Art. 7 para. 3 GDPR. As a result, we are no longer allowed to continue the data processing based on this consent for the future and
                                </LiTag>
                                <LiTag>
                                    Lodge a complaint with a supervisory authority in accordance with Art. 77 GDPR. As a rule, you can contact the supervisory authority of your usual place of residence or workplace or our company headquarters.</LiTag>
                            </UlTag>
                            <br />
                            <HeadingThree>Right to Object</HeadingThree>
                            <PTag>If your personal data is processed based on legitimate interests in accordance with Art. 6 para. 1 sentence 1 lit. f GDPR, you have the right to object to the processing of your personal data in accordance with Art. 21 GDPR, provided that there are reasons for this arising from your particular situation or the objection is directed against direct advertising. In the latter case, you have a general right to object, which we will implement without specifying a particular situation.</PTag>
                            <br />
                            <PTag>If you wish to exercise your right of revocation or objection, simply send an email to <a href="mailto:hello@beebox360.com">hello@beebox360.com</a>.</PTag>
                            <br />
                            <HeadingThree> Data Security</HeadingThree>
                            <PTag>We use the widespread SSL (Secure Socket Layer) procedure in connection with the highest encryption level supported by your browser during your website visit. As a rule, this is a 256-bit encryption. If your browser does not support 256-bit encryption, we use 128-bit v3 technology instead. You can recognize whether an individual page of our website is transmitted in encrypted form by the closed display of the key or lock symbol in the lower status bar of your browser.</PTag>
                            <br />
                            <PTag>We also use appropriate technical and organizational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorized access by third parties. Our security measures are continuously improved in line with technological developments.</PTag>
                            <br />
                            <HeadingThree> Currentness and Changes to this Privacy Policy</HeadingThree>
                            <PTag>This privacy policy is currently valid and has the status [Date].</PTag> <br />
                            <PTag>Due to the further development of our website and offers above or due to changed legal or official requirements, it may become necessary to change this privacy policy. The current privacy policy can be accessed and printed out at any time on the website at  <Link to="/privacy-policy" as={Link} onClick={handleLinkClick}>https://beebox360.com/privacy-policy</Link>.</PTag>


                        </Col>
                    </Row>
                </MyDiv>

            </Section>
        </>
    );
};

export default PrivacyPolicy;
